import { Button, Form, FormLayout, Frame, Layout, Loading, Page, TextField } from "@shopify/polaris";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";
import { UserFormValues } from "../../app/models/User";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ShopifyAuth } from "../../app/models/ShopifyAuth";

const LoginPage = () => {
  const {userStore} = useStore();

  const [storeDomain, setStoreDomain] = useState('');
  
  const [searchParams] = useSearchParams();
  
  const [authParamsPresent, setAuthParamsPresent] = useState(false); 

  let navigate = useNavigate();
  
  useEffect(() => {
    const authParams: ShopifyAuth = 
    {
      code: searchParams.get('code'),
      hmac: searchParams.get('hmac'),
      host: searchParams.get('host'),
      shop: searchParams.get('shop'),
      state: searchParams.get('state'),
      timeStamp: searchParams.get('timestamp')
    };

    // If parameters are present, user completed shopify sign in.
    // Complete sign-in localy next
    if (Object.values(authParams).some(x => x !== null && x !== ''))
    {
      // flag that params have been passed
      setAuthParamsPresent(true);

      // get user and set token
      const setToken = async () => {
        await userStore.login(authParams);
      }

      setToken();         
    };
    
  }, []);  

  useEffect(() => {
    if (userStore.isLoggedIn)
    {
      // redirect to homepage
      navigate('/home');   
    }
  }, [userStore.isLoggedIn]);


  const handleSubmit = () =>
  {
    let formValues: UserFormValues = { shopDomain: storeDomain};
    userStore.authenticate(formValues);    
  }

  const handleShopDomainChange = useCallback((value : any) => { 
    setStoreDomain(value);        
  }, [storeDomain]);

  if (authParamsPresent)
  {
    return (
      <div style={{ height: '100px' }}>
        <Frame>
          <Loading />
        </Frame>
      </div>
    );
  }

  return (
    <Page title="Login">
      <Layout>
      <Form onSubmit={handleSubmit}>
      <FormLayout>        

        <TextField
          value={storeDomain}
          onChange={handleShopDomainChange}
          label="Shopify store URL"
          type="text"
          helpText={
            <span>
             Enter your Shopify store URL to login. For example store.myshopify.com
            </span>
          }
          autoComplete="off"
        />

        <Button submit>Login</Button>
      </FormLayout>
    </Form>
      </Layout>
    </Page>
  );
};

export default observer(LoginPage);
