import { Form, Card, Page, TextField, PageActions, Box } from "@shopify/polaris";
import { observer } from "mobx-react-lite";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoadingGroupPage from "../../app/layout/skeletons/LoadingGroupPage";
import { Group } from "../../app/models/Group";
import { useStore } from "../../app/stores/store";
import { v4 as uuid } from 'uuid';

const GroupFormPage = () => {
  const { groupStore } = useStore();
  const { selectedGroup: group, loadGroup, loadingInitial, clearSelectedGroup, updateGroup, createGroup } = groupStore;
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [name, setName] = useState(group?.name || '');
  const [summary, setSummary] = useState(group?.summary || '');
  const [description, setDescription] = useState(group?.description || '');

  useEffect(() => {
    if (id) {
      loadGroup(id);
    }

    if (group) {
      setName(group.name);
      setSummary(group.summary);
      setDescription(group.description);
    }

    return () => clearSelectedGroup();
  }, [id, loadGroup, clearSelectedGroup, group]);

  const handleSubmit = async () => {
    let newGroup = {
      id: id,
      name: name,
      summary: summary,
      description: description
    } as Group;

    // TODO: ADD VALIDATION

    if (!newGroup.id) {
      newGroup.id = uuid();
      await createGroup(newGroup).then(() => navigate(`/groups/${newGroup.id}`));
    } else {
      await updateGroup(newGroup);
    }
  };

  const handleNameChange = useCallback((value: any) => setName(value), []);
  const handleSummaryChange = useCallback((value: any) => setSummary(value), []);
  const handleDescriptionChange = useCallback((value: any) => setDescription(value), []);

  const formMarkup = (
    <Form onSubmit={handleSubmit}>
      <Card>
        <Box padding="400">
          <TextField
            value={name}
            onChange={handleNameChange}
            label="Name"
            type="text"
            autoComplete="off"
            helpText=""
          />
        </Box>
      </Card>

      <Card>
        <Box padding="400">
          <TextField
            value={summary}
            onChange={handleSummaryChange}
            label="Summary"
            type="text"
            autoComplete="off"
          />
          <br />
          <TextField
            value={description?.toString()}
            onChange={handleDescriptionChange}
            label="Description"
            type="text"
            autoComplete="off"
          />
        </Box>
      </Card>

      <Card>
        <Box padding="400">
          {/* SubGroups content */}
        </Box>
      </Card>
    </Form>
  );

  if (loadingInitial) return <LoadingGroupPage />;

  return (
    <Page
      // breadcrumbs={[{ content: "Groups", url: "/groups" }]}
      title={group?.name || "Create Group"}
      primaryAction={!group?.id ? {
        content: 'Create',
        url: '/creategroup'
      } : undefined}
    >
      {formMarkup}

      <br />
      <PageActions
        primaryAction={{
          content: 'Save',
          onAction: handleSubmit
        }}
        secondaryActions={!group?.id ? [
          {
            content: 'Cancel',
            url: '/groups',
          },
        ] : [
          {
            content: 'Delete',
            destructive: true,
          },
        ]}
      />
    </Page>
  );
};

export default observer(GroupFormPage);
