import { ActionList, AppProvider, Button, Card, ContextualSaveBar, FormLayout, Frame, Layout, Loading, Modal, Navigation, SkeletonBodyText, SkeletonDisplayText, SkeletonPage, TextContainer, TextField, Toast, TopBar } from '@shopify/polaris';
import { RefreshIcon, HomeFilledIcon, StatusIcon } from '@shopify/polaris-icons';
import { observer } from 'mobx-react-lite';
import React, { Fragment, useCallback, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

const AppFrame = (props : any) => {


    const defaultState = useRef({
      emailFieldValue: 'support@govedia.com',
      nameFieldValue: 'GoVedia',
    });
    const skipToContentRef = useRef(null);
  
    const [toastActive, setToastActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDirty, setIsDirty] = useState(false);    
    const [userMenuActive, setUserMenuActive] = useState(false);
    const [mobileNavigationActive, setMobileNavigationActive] = useState(false);    
    const [nameFieldValue, setNameFieldValue] = useState(
      defaultState.current.nameFieldValue,
    );
    const [emailFieldValue, setEmailFieldValue] = useState(
      defaultState.current.emailFieldValue,
    );
    const [storeName, setStoreName] = useState(
      defaultState.current.nameFieldValue,
    );  

    const handleDiscard = useCallback(() => {
      setEmailFieldValue(defaultState.current.emailFieldValue);
      setNameFieldValue(defaultState.current.nameFieldValue);
      setIsDirty(false);
    }, []);
    const handleSave = useCallback(() => {
      defaultState.current.nameFieldValue = nameFieldValue;
      defaultState.current.emailFieldValue = emailFieldValue;
  
      setIsDirty(false);
      setToastActive(true);
      setStoreName(defaultState.current.nameFieldValue);
    }, [emailFieldValue, nameFieldValue]);
    
    const toggleToastActive = useCallback(
      () => setToastActive((toastActive) => !toastActive),
      [],
    );

    const toggleUserMenuActive = useCallback(
      () => setUserMenuActive((userMenuActive) => !userMenuActive),
      [],
    );

    const toggleMobileNavigationActive = useCallback(
      () =>
        setMobileNavigationActive(
          (mobileNavigationActive) => !mobileNavigationActive,
        ),
      [],
    );

    const toggleIsLoading = useCallback(
      () => setIsLoading((isLoading) => !isLoading),
      [],
    );
  
    const toastMarkup = toastActive ? (
      <Toast onDismiss={toggleToastActive} content="Changes saved" />
    ) : null;
  
    const userMenuActions = [
      {
        items: [{content: 'Community forums'}],
      },
    ];
  
    const contextualSaveBarMarkup = isDirty ? (
      <ContextualSaveBar
        message="Unsaved changes"
        saveAction={{
          onAction: handleSave,
        }}
        discardAction={{
          onAction: handleDiscard,
        }}
      />
    ) : null;
  
    const userMenuMarkup = (
      <TopBar.UserMenu
        actions={userMenuActions}
        name="GoVedia"
        detail={storeName}
        initials="GV"
        open={userMenuActive}
        onToggle={toggleUserMenuActive}
      />
    );
  
    const topBarMarkup = (            
      <TopBar
        showNavigationToggle
        userMenu={userMenuMarkup}
        onNavigationToggle={toggleMobileNavigationActive}                
      />         
    );
    
    const currentPath = useLocation().pathname;
    const navigationMarkup = (
      <Navigation location="/">
        {/* <Navigation.Section
          items={[
            {
              label: 'Back to Shopify',
              icon: ArrowLeftMinor,
            },
          ]}
        /> */}
        <Navigation.Section
          //separator
          title="Russell Hendrix"
          items={[
            // {
            //   label: 'Groups',
            //   icon: DynamicSourceMinor,
            //   // onClick: toggleIsLoading,   
            //   url: '/groups' ,
            //   selected: currentPath === '/groups'
            // }, 
            {
              label: 'Dashboard',
              icon: StatusIcon,
              // onClick: toggleIsLoading,   
              url: '/dashboard' ,
              selected: currentPath === '/dashboard'
            },  
            {
              label: 'Sync',
              icon: RefreshIcon,
              // onClick: toggleIsLoading,   
              url: '/sync' ,
              selected: currentPath === '/sync'
            },  
            // {
            //   label: 'Account',
            //   icon: CustomersMinor,
            //   // onClick: toggleIsLoading,   
            //   url: '/account' ,
            //   selected: currentPath === '/account'
            // },  
             
          ]}          
        />
      </Navigation>
    );
  
    const loadingMarkup = isLoading ? <Loading /> : null;
  
    const skipToContentTarget = (
      <a id="SkipToContentTarget" ref={skipToContentRef} tabIndex={-1} />
    );
  
    const actualPageMarkup = (
     <Fragment>
       {props.children}
     </Fragment>
    );
  
    const loadingPageMarkup = (
      <SkeletonPage>
        <Layout>
          <Layout.Section>
            <Card>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={9} />
              </TextContainer>
            </Card>
          </Layout.Section>
        </Layout>
      </SkeletonPage>
    );
  
    const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  
    const theme = {
      logo: {
        width: 124,
        //topBarSource:'https://www.govedia.com/android-chrome-512x512.png',
        contextualSaveBarSource:
          'https://www.govedia.com/android-chrome-512x512.png',
        url: 'http://govedia.com',
        accessibilityLabel: 'GoVedia',
      },
      colors: {
        
      }      
    };
  
    return (
        <AppProvider
          // theme={theme}
          i18n={{
            Polaris: {
              Avatar: {
                label: 'Avatar',
                labelWithInitials: 'Avatar with initials {initials}',
              },
              ContextualSaveBar: {
                save: 'Save',
                discard: 'Discard',
              },
              TextField: {
                characterCount: '{count} characters',
              },
              TopBar: {
                toggleMenuLabel: 'Toggle menu',
  
                SearchField: {
                  clearButtonLabel: 'Clear',
                  search: 'Search',
                }                
              },
              Modal: {
                iFrameTitle: 'body markup',
              },
              Frame: {
                skipToContent: 'Skip to content',
                navigationLabel: 'Navigation',
                Navigation: {
                  closeMobileNavigationLabel: 'Close navigation',
                },
              },
            },
          }}
        >
          <Frame
            // topBar={topBarMarkup}
            navigation={navigationMarkup}
            showMobileNavigation={mobileNavigationActive}
            onNavigationDismiss={toggleMobileNavigationActive}
            //skipToContentTarget={skipToContentRef.current}
          >
            {contextualSaveBarMarkup}
            {loadingMarkup}
            {pageMarkup}
            {toastMarkup}
          </Frame>
        </AppProvider>
    );
  }

  export default AppFrame;