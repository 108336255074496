import React from "react";
import ReactDOM from 'react-dom/client';
import { AppProvider, Frame } from "@shopify/polaris";
import { store, StoreContext } from "./app/stores/store";
import "@shopify/polaris/build/esm/styles.css";
import "./styles.css";
import { BrowserRouter, RouterProvider } from "react-router-dom";
import { router } from "./app/router/Routes";
import reportWebVitals from "./reportWebVitals";
// export const history = createBrowserHistory();


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <AppProvider
    i18n={{
      Polaris: {
        Avatar: {
          label: "Avatar",
          labelWithInitials: "Avatar with initials {initials}",
        },
        Frame: { skipToContent: "Skip to content" },
        TopBar: {
          toggleMenuLabel: "Toggle menu",
          SearchField: {
            clearButtonLabel: "Clear",
            search: "Search",
          },
        },
      },
    }}
  >
      <StoreContext.Provider value={store}>
        <RouterProvider router={router} />
      </StoreContext.Provider>
  </AppProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();