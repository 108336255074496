import React, { Fragment, useEffect } from "react";
import { Outlet, Route, RouterProvider, Routes, useRoutes } from "react-router-dom";
import { observer } from "mobx-react-lite";
import AppFrame from "./AppFrame";
import { useStore } from "../stores/store";
import { Loading } from "@shopify/polaris";
import HomePage from "../../features/home/Dashboard";
import LoginPage from "../../features/users/LoginPage";
import SettingsPage from "../../features/settings/SettingsPage";

const App = () => {
  const { commonStore, userStore } = useStore();

  useEffect(() => {
    if (commonStore.token) {
      userStore.getUser().finally(() => commonStore.setAppLoaded());
    } else {
      commonStore.setAppLoaded();
    }
  }, [commonStore, userStore])

  if (!commonStore.appLoaded) return (<Fragment><AppFrame><div style={{ height: '100px' }}><Loading /></div></AppFrame></Fragment>)

  if (!userStore.isLoggedIn) return (<Fragment><LoginPage /></Fragment>)

  return (
    <AppFrame>
      {window.location.pathname === '/' ? <SettingsPage /> : (
        <Outlet />
      )}
    </AppFrame>
  )

};

export default observer(App);