import { createContext, useContext } from "react";
import CommonStore from "./commonStore";
import UserStore from "./userStore";
import SettingStore from "./settingStore";
import GroupStore from "./groupStore";
import SyncLogStore from "./syncLogStore";
import pricingStore from "./pricingStore";

interface Store {
    commonStore: CommonStore;
    userStore: UserStore;
    settingStore: SettingStore;
    groupStore: GroupStore;
    syncLogStore: SyncLogStore;
    pricingStore: pricingStore;
}

export const store: Store = {
    commonStore: new CommonStore(),
    userStore: new UserStore(),
    settingStore: new SettingStore(),
    groupStore: new GroupStore(),
    syncLogStore: new SyncLogStore(),
    pricingStore: new pricingStore(),
}

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}