import {
  SkeletonPage,
  Layout,
  Card,
  SkeletonBodyText,
  TextContainer,
  SkeletonDisplayText,
  Divider,
} from '@shopify/polaris';
import React from 'react';

const LoadingGroupPage = () => {
  return (
    <SkeletonPage primaryAction>
      <Layout>
        <Layout.Section>
          <Card>
            <SkeletonBodyText />
          </Card>
          <Card>
            
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText />
              </TextContainer>
              <Divider />
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText />
              </TextContainer>
            
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );
}

export default LoadingGroupPage;