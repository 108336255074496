import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { ShopifyAuth } from "../models/ShopifyAuth";
import { User, UserFormValues } from "../models/User";
import { store } from "./store";

export default class UserStore {
    user: User | null = null;    

    constructor() {
        makeAutoObservable(this)
    }
    
    get isLoggedIn() {        
        return !!this.user;
    }

    authenticate = async (creds: UserFormValues) => {  
        try {            
            // We're going to use Shopify to authenticate user
            // Get authentication URL for shop 
            const shopifyAuth = await agent.Account.auth(creds);
            window.location.replace(shopifyAuth); 
        } catch (error) {
            throw error;
        }
    }

    login = async (params: ShopifyAuth) => {        
        try {
            // Once user has been authenticated through Shopify's OAuth, we create a local session
            // See AccountController for details
            const user = await agent.Account.login(params);
            // We get the user object back, including a token, and set the token to memory
            store.commonStore.setToken(user.token);
            runInAction (() => this.user = user);                 
        } catch (error) {
            console.log(error);
        }        
    }
   
    logout = () => {
        store.commonStore.setToken(null);
        window.localStorage.removeItem('govedia-base-jwt');
        this.user = null;               
    }
    
    getUser = async () => {        
        try
        {   const user = await agent.Account.current();
            console.log(user);
            runInAction(() => this.user = user);            
        } catch (error) {
            console.log(error);
        }
    }
}

