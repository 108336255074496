import { Fragment } from "react";

interface Props {
    errors: any;
}

export default function ValidationError({errors}: Props) {
    return (
        <Fragment>
        {errors && (
        <ul>
            {errors.map((err: string, i: any) => (
                        <li key={i}>{err}</li>
                    ))}
        </ul>
        )}
        </Fragment>
        
        // <Message error>
        //     {errors && (
        //         <Message.List>
        //             {errors.map((err: string, i: any) => (
        //                 <Message.Item key={i}>{err}</Message.Item>
        //             ))}
        //         </Message.List>
        //     )}
        // </Message>
    )
}