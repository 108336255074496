import { Button, Card, Select, SkeletonBodyText, Modal, TextContainer, Box, Text, BlockStack, Divider, ButtonGroup } from "@shopify/polaris";
import { observer } from "mobx-react-lite";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useStore } from "../../app/stores/store";
import { PricePullHistory } from "../../app/models/Pricing";

const PriceRestoreCard = () => {
    const { pricingStore } = useStore();
    const { priceHistoryRecords, priceRestore, lastShopifyPriceUpdate, loadingRestore, loadingInitial } = pricingStore;

    const [pricingInfoLoaded, setPricingInfoLoaded] = useState(false);
    const [selectedPriceRestoreGuid, setSelectedPriceRestoreGuid] = useState('');
    const [priceRestoreOptions, setPriceRestoreOptions] = useState<{ label: string; value: string; }[]>([]);
    const [createRestoreRequestInProgress, setCreateRestoreRequestInProgress] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [lastUpdateTimestamp, setLastUpdateTimestamp] = useState<string | null>(null);

    useEffect(() => {
        const fetchPricingInfo = async () => {
            await pricingStore.loadPricingInfo();
            setPricingInfoLoaded(true);
        };

        if (pricingInfoLoaded === false) {
            fetchPricingInfo();
        } else if (pricingInfoLoaded && priceHistoryRecords) {
            const options = priceHistoryRecords.map((historyItem: PricePullHistory) => {
                return {
                    label: historyItem.timestamp + " UTC",
                    value: historyItem.pullLogGuid,
                    key: historyItem.pullLogGuid
                };
            });

            options.sort((a, b) => {
                const dateA = new Date(a.label);
                const dateB = new Date(b.label);
                if (dateA > dateB) return -1;
                if (dateA < dateB) return 1;
                return 0;
            });

            setPriceRestoreOptions(options);
            setSelectedPriceRestoreGuid(options[0]?.value || '');

            const latestTimestamp = priceHistoryRecords[0]?.timestamp || null;
            setLastUpdateTimestamp(latestTimestamp);
        }
    }, [pricingInfoLoaded]);

    const handleSelectPriceDateChange = useCallback(
        (value: string) => setSelectedPriceRestoreGuid(value),
        [],
    );

    const handlePriceRestoreClick = async () => {
        if (priceRestore && priceRestore.requestedAt != null) {
            setIsModalOpen(true);
            return;
        }

        handlePriceRestore();
    };

    const handlePriceRestore = async () => {
        let priceHistory = {
            pullLogGuid: selectedPriceRestoreGuid,
        } as PricePullHistory;

        setCreateRestoreRequestInProgress(true);

        await pricingStore.createPriceRestoreRequest(priceHistory);

        setCreateRestoreRequestInProgress(loadingRestore);
    };

    const handleModalConfirm = () => {
        handlePriceRestore();
        setIsModalOpen(false);
    };

    const handleModalCancel = () => {
        setIsModalOpen(false);
    };

    if (loadingInitial)
        return (
            <Card>
                <Box padding="400">
                    <SkeletonBodyText />
                </Box>
            </Card>
        );

    return (
        <Fragment>
            <Card>
                <Text as="h2" variant="headingSm">
                    Pricing
                </Text>
                {!priceRestore || !priceRestore.inProgress ? (

                    <BlockStack gap="400">
                        <Text as="p" variant="bodyMd">Shopify last updated on {lastShopifyPriceUpdate ? lastShopifyPriceUpdate.completedAt : 'N/A'}</Text>

                        <Divider />

                        <BlockStack gap="200">

                            <Text as="p" variant="bodyMd">Restore from</Text>
                            <Select
                                label=""
                                options={priceRestoreOptions}
                                onChange={handleSelectPriceDateChange}
                                value={selectedPriceRestoreGuid}
                            />
                            <ButtonGroup>
                                <Button fullWidth={false} size="medium" variant="primary" loading={createRestoreRequestInProgress} onClick={handlePriceRestoreClick}>Restore</Button>
                            </ButtonGroup>
                        </BlockStack>
                    </BlockStack>

                ) : null}
                {priceRestore && (
                    <BlockStack gap="200">
                        {priceRestore && (
                            <Fragment>
                                {!priceRestore.inProgress ? (
                                    <Fragment>
                                        Enqueued to restore from {priceRestore.fromTimeframe} UTC
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        Restoring pricing data from {priceRestore.fromTimeframe} UTC
                                    </Fragment>
                                )}
                            </Fragment>
                        )}
                    </BlockStack>
                )}
            </Card>

            <Modal
                open={isModalOpen}
                onClose={handleModalCancel}
                title="Restore already in queue"
                primaryAction={{
                    content: 'Confirm',
                    onAction: handleModalConfirm,
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: handleModalCancel,
                    },
                ]}
            >
                <Modal.Section>
                    <TextContainer>
                        <p>There is already a restore request. Enqueuing another request will replace the existing one. Are you sure you want to proceed?</p>
                    </TextContainer>
                </Modal.Section>
            </Modal>
        </Fragment>
    );
};

export default observer(PriceRestoreCard);
