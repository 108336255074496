import { Navigate, RouteObject, createBrowserRouter } from "react-router-dom";
import App from "../layout/App";
import LoginPage from "../../features/users/LoginPage";
import RequireAuth from "./RequireAuth";
import TestErrors from "../../features/errors/TestError";
import NotFound from "../../features/errors/NotFound";
import ServerError from "../../features/errors/ServerError";
import GroupFormPage from "../../features/group/GroupFormPage";
import SettingsPage from "../../features/settings/SettingsPage";
import AccountPage from "../../features/users/AccountPage";
import Dashboard from "../../features/home/Dashboard";
import SyncPage from "../../features/sync/SyncPage";

export const routes: RouteObject[] = [
    {
        path: '/',
        element: <App />,
        children: [
            {element: <RequireAuth />, children: [  
                {path: '/', element: <Dashboard/> },              
                {path: 'dashboard', element: <Dashboard />},
                {path: 'groups', element: <GroupFormPage />},
                {path: 'groups/:id', element: <GroupFormPage key='manage' />},
                {path: 'creategroup', element: <GroupFormPage key='create' />},
                {path: 'settings', element: <SettingsPage />},
                {path: 'sync', element: <SyncPage />},
                {path: 'account', element: <AccountPage />},
                {path: 'errors', element: <TestErrors />}
            ]},
            // {path: 'not-found', element: <NotFound />},
            {path: 'not-found', element: <Dashboard />},
            {path: 'server-error', element: <ServerError />},
            {path: '*', element: <Navigate replace to='/not-found' />},
        ]
    }
]

export const router = createBrowserRouter(routes);