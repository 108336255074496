    import { makeAutoObservable, reaction, runInAction } from 'mobx';
    import agent from '../api/agent';
    import { LastShopifyPriceUpdate, PricePullHistory, PriceRestore, Pricing } from '../models/Pricing';

    export default class PricingStore {
        priceHistoryRegistry = new Map<string, PricePullHistory>();
        selectedPriceHistory: PricePullHistory | undefined = undefined;
        priceRestore: PriceRestore | null = null;  
        lastShopifyPriceUpdate: LastShopifyPriceUpdate | null = null;
        loading = false;
        loadingInitial = false;
        loadingRestore = false;

        constructor() {
            makeAutoObservable(this);
        }

        loadPricingInfo = async () => {
            this.loadingInitial = true;
            try {
                const PricingInfo = await agent.PricingInfo.details();           
                PricingInfo.pricePullHistory.forEach(PriceHistory => {
                    this.setPriceHistory(PriceHistory);
                })
                runInAction(() => this.priceRestore = PricingInfo.priceRestore ?? null);   
                runInAction(() => this.lastShopifyPriceUpdate = PricingInfo.lastShopifyPriceUpdate ?? null);   
                this.setLoadingInitial(false);
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }

        createPriceRestoreRequest = async (pricePullHistory: PricePullHistory) => { 
            this.loadingRestore = true;
            
            try {                        
                await agent.PricingInfo.restore(pricePullHistory);             
                
                runInAction(() => {
                    this.loadPricingInfo();
                });          

                this.loadingRestore = false;      
                return(true);
            } 
            catch (error) {            
                this.loadingRestore = false;
                return(false);             
            }
        }

        private setPriceHistory = (pricePullHistory: PricePullHistory) =>
        {
            this.priceHistoryRegistry.set(pricePullHistory.pullLogGuid, pricePullHistory);
            this.selectedPriceHistory = pricePullHistory;
        }

        get priceHistoryRecords(): PricePullHistory[] {
            return Array.from(this.priceHistoryRegistry.values());
        }

        setLoadingInitial = (state: boolean) => {
            this.loadingInitial = state;
        }


    }