import { makeAutoObservable, reaction, runInAction } from 'mobx';
import agent from '../api/agent';
import { Group, GroupFormValues } from '../models/Group';

export default class GroupStore {
    groupRegistry = new Map<string, Group>();
    selectedGroup: Group | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this);
    }

    loadGroups = async () => {
        this.loadingInitial = true;
        // we'll place asynchronous code within the try catch block
        // we use external action methods to retain strict mode
        try {
            const Groups = await agent.Groups.list();
            Groups.forEach(Group => {
                this.setGroup(Group);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadGroup = async (id: string) => {
        let group = this.getGroup(id);
        if (group) {
            this.selectedGroup = group;
            //return group;
        }
        else {
            this.setLoadingInitial(true);
            try {
                group = await agent.Groups.details(id);                
                this.setGroup(group);
                runInAction(() => this.selectedGroup = group);                
                this.setLoadingInitial(false);
                //return group;
            }
            catch (error)
            {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }
    }

    updateGroup = async (group: GroupFormValues) => {
        try {
            await agent.Groups.update(group);
            runInAction(() => {
                if (group.id) {
                    let updateGroup = { ...this.getGroup(group.id), ...group };
                    this.groupRegistry.set(group.id, updateGroup as Group);
                    this.selectedGroup = updateGroup as Group;
                }
            })
        } catch (error) {
            console.log(error);
        }
    }

    createGroup = async (group: Group) => {
        this.loading = true;
        try {
            await agent.Groups.create(group);
            runInAction(() => {
                this.groups.push(group);
                this.selectedGroup = group;
                this.editMode = false;
                this.loading = false;
            })
        } 
        catch (error)
        {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })

        }
    }

    deleteGroup = async (id: string) => {
        this.loading = true;
        try
        {
            await agent.Groups.delete(id);
            runInAction(() => {
                this.groupRegistry.delete(id);
                this.loading = false;
            })
        }
        catch (error)
        {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    private setGroup = (group: Group) =>
    {
        this.groupRegistry.set(group.id, group);
        this.selectedGroup = group;
    }

    private getGroup = (id: string) => {
        return this.groupRegistry.get(id);
    }


    get groups(): Group[] {
        return Array.from(this.groupRegistry.values());
    }

    get group(): Group | undefined {
        return this.selectedGroup;
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    clearSelectedGroup = () => {
        this.selectedGroup = undefined;
    }

}