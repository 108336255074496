import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Setting } from "../models/Setting";

export default class SettingStore {
    // Using javascript Map object to store our key-value paired data, instead of a regular array, since it will give us more power/options
    settingRegistry = new Map<string, Setting>();
    currentSettings: Setting | undefined = undefined;
    loadingInitial = true;
    loadingUpdate = false;

    constructor() {
        makeAutoObservable(this)
    }

    get settings() {
        return Array.from(this.settingRegistry.values());
    }

    loadSettings = async () => {
        //this.setLoadingInitial(true);

        try {
            const settings = await agent.Settings.details();

            this.setSetting(settings);
            this.setCurrentSettings(settings);
            this.setLoadingInitial(false);

        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    updateSettings = async (setting: Setting) => { 
        this.loadingUpdate = true;
        
        try {                        
            await agent.Settings.update(setting);             
            
            runInAction(() => {
                // re-load fitments
                this.loadSettings();
            });          

            this.loadingUpdate = false;      
            return(true);
        } 
        catch (error) {            
            this.loadingUpdate = false;
            return(false);             
        }
    }

    private setSetting = (setting: Setting) => {
        this.settingRegistry.set(setting.id, setting);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    private setCurrentSettings = (setting: Setting) => {
        this.currentSettings = setting;
    }
}